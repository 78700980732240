import type { ItemModel, DataItemModel, RecordSourceOptions } from 'o365-dataobject';
import type { DataGridControl } from 'o365-datagrid';
import type { INodeDataLevelConfiguration, NodeDataConfigurationOptions } from './types.ts'

import { DataObject, Item as DataItem, DataHandler } from 'o365-dataobject';
import { VersionedStorage, EventEmitter } from 'o365-modules';
import { importUtils, logger, Url } from 'o365-utils';
import { markRaw } from 'vue';

import { NodeItem, type ICalculatedField } from './DataObject.NodeItem.ts';
import { HierarchyLevelConfiguration, type NodeDataHierarchyConfigurationOptions } from './DataObject.Configurations.Hierarchy.ts';
import { GroupByLevelConfiguration, type NodeDataGroupByConfigurationOptions } from './DataObject.Configurations.GroupBy.ts'
import { INodeData } from './types';
import { UIMessageChannel } from './worker.UIChannel.ts';
import { getPromise } from './DataObject.utils.ts';

Object.defineProperties(DataObject.prototype, {
    'nodeData': {
        get() {
            if (this._nodeData == null) {
                this._nodeData = new NodeData(this);
                this._nodeData.initialize();
            }
            return this._nodeData;
        },
        configurable: true
    },
    'hasNodeData': {
        get() {
            return !!this._nodeData;
        },
        configurable: true
    }
});

export default class NodeData<T extends ItemModel = ItemModel> implements INodeData<T> {
    $: {
        dataObject: DataObject<T>,
        localStorage: VersionedStorage<Record<string, true>, { id: string }>;
        /** Events emitter utilized by other controls for reacting to node data changes */
        events: EventEmitter<NodeDataEvents<T>>;
        worker?: Worker;
        channel?: UIMessageChannel;

        /** Original DataObject load */
        _dataObject_load: DataObject<T>['load'];
        /** Original RecordSource retrieve */
        _recordSource_retrieve: DataObject<T>['recordSource']['retrieve'];
    };

    private _initialized = false;
    private _enabled = false;
    private _configurations: INodeDataLevelConfiguration<T>[] = [];
    private _root: NodeItem<T>[] = [];
    private _data: DataItemModel<T>[] = [];
    private _updated: Date = new Date();
    private _resolveWorkerInitPromise: (pSuccess: boolean) => void;

    /** When true will auto expand all nodes after filtering */
    autoExpandOnFilter = true;
    /**
     * When true will load all of the necessary fields for all of the rows with the current whereClause/filterString. The node
     * structure will then be constructed on client. This mode is not suited for very large datasets and is geared more towards <20k rows.
     */
    loadFullStructure = false;
    /** Current expanded level used by other controls */
    currentLevel = 0;
    /** Deepest level in the structure */
    deepestLevel = 0;
    /** When true setting isSelected will not update detail nodes isSelected values */
    disableDetailsMultiSelect = false;
    /** When true will not restore expanded states from local store */
    disableLocalStore = false;
    /** When set to true will disable auto new record on empty roots */
    disableAutoNewRecord = false;
    /** Opt-in for NodeData layouts. Will be enabled by default once stable */
    enableLayouts = false;
    /** Promise that is resovled when the worker is initialized */
    workerInitPromise?: Promise<boolean>;

    /**
     * View that will be used for grouping system properties. 
     * This view needs to have properties values table joined in onto the main view and
     * select [PropertyName] with [Value] AS 'PropertyName' in addition to the same fields as the main view.
     */
    withPropertiesView?: string;
    withPropertiesDefinitionProc?: string;

    get localStorageKey() {
        return `${this.$.dataObject.appId}_${this.$.dataObject.id}_nodeData`
    }

    get events() {
        return this.$.events;
    }

    get configurations() { return this._configurations; }


    constructor(pDataObject: DataObject<T>) {
        this.$ = markRaw({} as any);
        this.$.dataObject = pDataObject;
        this.$.localStorage = new VersionedStorage({
            baseline: { id: `${this.$.dataObject.id}` },
            id: this.localStorageKey,
        });
        this.$.events = new EventEmitter();
    }

    /** Should not be called outside of DataObject */
    initialize() {
        if (this._initialized) { return; }
        this._initialized = true;
        const promiseObj = getPromise<boolean>();
        this.workerInitPromise = promiseObj.promise;
        this._resolveWorkerInitPromise = promiseObj.resolve;

        // Original DataObject functions
        this.$._dataObject_load = this.$.dataObject.load.bind(this.$.dataObject);
        this.$._recordSource_retrieve = this.$.dataObject.recordSource.retrieve.bind(this.$.dataObject.recordSource);
        this._createWorker();
    }

    /** Enable NodeData overrides on the DataObject */
    enable() {
        if (this._enabled) { return; }
        this._enabled = true;
        this._data.length = 0;

        this.$.dataObject.setStoragePointer(this._data);

        this.$.dataObject.createNewAtTheEnd = true;

        this.$.dataObject.load = this.load.bind(this);
        // this.$.dataObject.recordSource.retrieve = this.retrieve.bind(this);

    }


    /** Disable NodeData overrides on the DataObject */
    disable() {
        if (!this._enabled) { return; }

        this._enabled = false;
        // this._dataObject.setStoragePointer(undefined);

        // this._dataObject.load = this._dataObject_load;
        // this._dataObject.recordSource.retrieve = this._recordSource_retrieve;

        // if (this._cancelAfterDelete) {
        //     this._cancelAfterlDelete();
        //     this._cancelAfterDelete = undefined;
        // }
        // if (this._cancelChangesCancelled) {
        //     this._cancelChangesCancelled();
        //     this._cancelChangesCancelled = undefined;
        // }
        // if (this._cancelAfterSave) {
        //     this._cancelAfterSave();
        //     this._cancelAfterSave = undefined;
        // }
        // if (this._hasDynamicLoading != null) {
        //     this._dataObject.dynamicLoading.enabled = this._hasDynamicLoading;
        // }
    }

    /** Push new level of structure configuration */
    addConfiguration(pOptions: NodeDataHierarchyConfigurationOptions<T> | NodeDataGroupByConfigurationOptions<T>, pLevel?: number) {
        if (pOptions.type == null) {
            (pOptions as any).type = 'hierarchy';
            logger.warn(`${this.$.dataObject.id}.nodeData.addConfiguration: Provided configuration has no type. Should be either 'hierarchy' or 'groupBy'`);
        }

        let config: INodeDataLevelConfiguration<T> | null = null
        if (pOptions.type === 'hierarchy') {
            config = new HierarchyLevelConfiguration(this.$.dataObject, pOptions, (pLevel) => {
                return this._configurations[pLevel];
            });
        } else {
            config = new GroupByLevelConfiguration(this.$.dataObject, pOptions, (pLevel) => {
                return this._configurations[pLevel];
            });
        }
        if (pLevel == null && this._configurations.at(-1)?.type === 'hierarchy') {
            pLevel = this._configurations.length - 1;
        }
        if (pLevel != null && pLevel < this._configurations.length) {
            this._configurations.splice(pLevel, 0, config!);
        } else {
            this._configurations.push(config!);
        }
        this._configurations.forEach((configuration, index) => configuration.level = index);
        this.events.emit('ConfigurationAdded');
    }

    // --- DataObject overrides ---

    /**
     * DataObject.load override
     * @ignore
     */
    async load(...[pOptions]: Parameters<DataObject<T>['load']>): ReturnType<DataObject<T>['load']> {
        if (this.$.dataObject.state.isLoading) { return; }
        if (this._configurations.length == 0 || this._configurations.every((config) => config.disabled)) {
            this.disable();
            return this.$._dataObject_load(pOptions);
        }
        await this.workerInitPromise;

        await this._passConfigurationsToWorker();
        const options = this.$.dataObject.recordSource.getOptions();

        debugger
        const data = await this.$.channel!.execute('retrieve', {
            type: 'clientSide',
            options: {
                dataSourceId: options.dataSourceId,
                definitionProc: options.definitionProc,
                distinctRows: options.distinctRows,
                expandView: options.expandView,
                filterString: options.filterString,
                masterDetailString: options.masterDetailString,
                viewName: options.viewName,
                whereClause: options.whereClause
            }
        }, -1);
        debugger
    }

    // --- Worker functions ---
    private async _passConfigurationsToWorker(pCt?: AbortSignal) {
        await this.workerInitPromise;
        if (pCt?.aborted) { return; }
        await this.$.channel!.execute('passConfigurations', this._configurations.map((configuration) => {
            if (configuration.type == 'groupBy') {
                return {
                    type: 'groupBy',
                    fieldName: 'OrgUnit'
                }
            } else {
                return {
                    type: 'hierarchy'
                }
            }
        }));
    }

    private _createWorker() {
        const workerUrl = import.meta.resolve('./worker.main.ts');
        const workerId = `${this.$.dataObject.id}-NodeData-Worker`;
        this.$.worker = new Worker(workerUrl, {
            name: workerId,
            type: 'module',
        });
        this.$.channel = new UIMessageChannel({
            id: workerId,
            functions: {
                'ping': () => {
                    console.log('ping from worker', performance.now());
                    return Promise.resolve(true);
                }
            },
            onConnected: () => {
                this._resolveWorkerInitPromise(true);
                console.log('worker created, can start grouping')
                this._passConfigurationsToWorker();
            }
        });
        this.$.channel.connect(this.$.worker);
    }

}

type NodeDataEvents<T extends ItemModel = ItemModel> = {
    'NodeAdded': (node: NodeItem<T>) => void,
    'ExpandedToNode': (node: NodeItem<T>) => void,
    'SetGroupBy': () => void,
    'AfterIndent': (pNode: NodeItem<T>) => void,
    'AfterOutdent': (pNode: NodeItem<T>) => void,
    'ConfigurationAdded': () => void,
    'ConfigurationRemoved': () => void,
    'LayoutApplied': () => void,
};
